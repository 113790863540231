/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { DownOutlined, RightOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form, Input, Radio, RadioChangeEvent, Select, Space, Typography, Upload, notification } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useRef, useState } from "react";
import './index.scss'
import DemoNoti from "./compoment/demoNoti";
import ReactQuill from "react-quill";
import { resizeImage } from "./compoment/resizeImage";
import axios from "axios";
import Cookies from 'js-cookie'
interface Props {
    onChangeForm: any,
    form: any,
    isEdit: any
}

const Step1 = (props: Props) => {
    const { onChangeForm, form, isEdit } = props
    const [showBox, setShowBox] = useState(false);
    const [name, setName] = useState("")
    const [valueRadio, setValueRadio] = useState("");
    const [image, setImage] = useState("");
    const [text, setText] = useState("")
    const [url, setUrl] = useState("");
    const [type, setType] = useState("")
    const [isNull, setIsNull] = useState(false)
    const [loading, setLoading] = useState(false)

    // Hoàn tất step 1
    const onDoneStep1 = () => {
        if (checkNull()) return
        if (!(url.includes("https://")) && valueRadio === "1") {
            notification.error({
                message: 'Thông báo!',
                description: <>Url sai định dạng</>
            })
            return
        }
        onChangeForm("name", name)
        onChangeForm("title", text)
        if (valueRadio === "1") {
            onChangeForm("url", url)
        } else {
            onChangeForm("type", type)
        }
        onChangeForm("images", image ? image : "")
        onChangeForm("step1", true)
        notification.success({
            message: 'Thông báo!',
            description: <>Tạo nội dung thông báo thành công</>
        })
        setShowBox(false)
    };

    const onChange = (e: RadioChangeEvent) => {
        setValueRadio(e.target.value);
    };

    // upload ảnh
    const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoading(true);
        const file = event.target.files[0];
        if (file) {
            try {
                setLoading(true);
                const resizedImage = await resizeImage(file, 600, 800);
                await uploadImage(resizedImage);
            } catch (error) {
                console.error('Error resizing image:', error);
            } finally {
                setLoading(false);
            }
        } else {
            console.log('No image selected');
            notification.error({
                description: 'Vui lòng chọn ảnh',
                message: 'Thông báo',
            });
        }
    };
    // upload ảnh
    const uploadImage = async (resizedImage: Blob) => {
        const AccessToken = Cookies.get('AccessToken') || '';
        if (loading) return;
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('photo', resizedImage, 'resized.jpg');
            const response = await axios.post(`${process.env.REACT_APP_UPLOAD_STAFF}/staff/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${AccessToken}`,
                },
            });
            if (response.data.data.image_url) {
                setImage(response.data.data.image_url)
            }
        } catch (error) {
            notification.error({
                description: 'Có lỗi xảy ra, vui lòng thử lại sau',
                message: 'Thông báo',
            });
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (value: string) => {
        setType(value)
    };

    // Kiểm tra null
    const checkNull = () => {
        if (!(name && valueRadio && text && (url || type))) {
            setIsNull(true)
            return true
        } else {
            setIsNull(false)
            return false
        }
    };

    // lấy dữ liệu mặc định
    useEffect(() => {
        if (form && isEdit) {
            setName(form.name)
            setValueRadio(form?.url ? "1" : "2")
            setText(form.title)
            setUrl(form.url || "")
            setType(form.type ? form.type.toString() : "")
            setImage(form.images)
        }
    }, [isEdit])

    return (
        <div className="p-[10px]" >
            <div className='p-[10px] flex flex-row justify-between drop-shadow-md' style={{ backgroundColor: showBox ? "#DAEEF9" : "white" }} onClick={() => setShowBox(!showBox)} >
                <div className="flex items-center justify-center">
                    <Typography.Title
                        level={5}
                        style={{ margin: 0 }}
                    >
                        Tạo nội dung thông báo
                    </Typography.Title>
                </div>
                <div className="flex items-center justify-center text-lg">
                    {showBox ?
                        <DownOutlined />
                        :
                        <RightOutlined />
                    }
                </div>
            </div>
            {showBox && form ? (
                <div className="bg-white drop-shadow-md">
                    <div className='p-[20px] body'>
                        <div className="grid grid-cols-3 gap-4">
                            <div className="col-span-2">
                                <div className="grid gap-4 grid-colsss-3">
                                    <Form
                                        className="max-w-[100%]"
                                        name="basic"
                                        labelCol={{ span: 8 }}
                                        wrapperCol={{ span: 16 }}
                                    >
                                        <Form.Item
                                            label={
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ color: "red" }}>*</div>
                                                    <div>Tên thông báo</div>
                                                </div>
                                            }
                                        >
                                            <Input placeholder="Tên thông báo" value={name} onChange={(e) => setName(e.target.value)} />
                                            {isNull && name.length === 0 ?
                                                <div style={{ color: "red" }}>Nhập tên thông báo</div>
                                                :
                                                null
                                            }
                                        </Form.Item>

                                        <Form.Item
                                            // label="Nội dung thông báo"
                                            label={
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ color: "red" }}>*</div>
                                                    <div>Nội dung thông báo</div>
                                                </div>
                                            }
                                        >
                                            <TextArea
                                                autoSize={{ minRows: 5, maxRows: 10 }}
                                                maxLength={4999} value={text}
                                                onChange={e => setText(e.target.value)}
                                            />
                                            {isNull && text.length === 0 ?
                                                <div style={{ color: "red" }}>Nhập nội dung thông báo</div>
                                                :
                                                null
                                            }
                                        </Form.Item>

                                        <Form.Item
                                            label={
                                                <Radio.Group onChange={onChange} value={valueRadio}>
                                                    <Radio value="1">URL thông báo</Radio>
                                                </Radio.Group>
                                            }
                                            rules={[{ required: !(url?.includes("https://")), message: 'Bắt buộc nhập Url' }]}
                                        >
                                            <Input disabled={valueRadio != "1"} value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Nhập Url" />
                                            {isNull && !valueRadio ?
                                                <div style={{ color: "red" }}>Nhập nơi hiển thị thông báo</div>
                                                :
                                                null
                                            }
                                        </Form.Item>
                                        <Form.Item
                                            label={
                                                <Radio.Group onChange={onChange} value={valueRadio}>
                                                    <Radio value="2">Hiển thị trên app</Radio>
                                                </Radio.Group>
                                            }
                                            rules={[{ required: !valueRadio, message: 'Bắt buộc chọn' }]}
                                        >
                                            <Select
                                                placeholder="Chọn thông báo"
                                                disabled={valueRadio != "2"}
                                                onChange={handleChange}
                                                defaultValue={type}
                                            >
                                                <Select.Option value="3">Customer Booking</Select.Option>
                                                <Select.Option value="4">Customer Profile</Select.Option>
                                                <Select.Option value="5">Customer Agile Question</Select.Option>
                                                <Select.Option value="6">Customer Store</Select.Option>
                                                <Select.Option value="7">Customer Webview</Select.Option>
                                                <Select.Option value="8">Customer Reward</Select.Option>
                                            </Select>
                                            {isNull && !valueRadio ?
                                                <div style={{ color: "red" }}>Nhập nơi hiển thị thông báo</div>
                                                :
                                                null
                                            }
                                        </Form.Item>

                                        <Form.Item label='Ảnh' name='images'>
                                            <div className='button-cover'>
                                                <label className='custom-file-label'>
                                                    <span className='button-content'>
                                                        <UploadOutlined className='icon' />
                                                        <span className="text">Choosen file</span>
                                                    </span>
                                                    <input className='custom-file-input' type='file' accept='image/*' onChange={handleFileInputChange} />
                                                </label>
                                            </div>
                                        </Form.Item>
                                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                            <Button type="primary" onClick={onDoneStep1}>
                                                Done
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            </div>
                            <div className="flex flex-col gap-7">
                                <div>
                                    <DemoNoti
                                        name={name}
                                        note={text}
                                        image={image}
                                        type={1}
                                    />
                                </div>
                                <div>
                                    <DemoNoti
                                        name={name}
                                        note={text}
                                        image={image}
                                        type={2}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            ) : null}
        </div>
    )
}
export default Step1
